import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VDataTable, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.table,
      expression: "table"
    }],
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "dense": "",
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VToolbar, {
          attrs: {
            "flat": "",
            "color": "white"
          }
        }, [_c(VTextField, {
          staticClass: "mt-8",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('LBAButton', {
          staticClass: "mt-1 ml-5",
          on: {
            "click": _vm.addItem
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                staticClass: "mr-2"
              }, [_vm._v("mdi-plus ")]), _vm._v("Neuer Datensatz ")];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }, _vm._l(_vm.headers, function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function ({
          item
        }) {
          return [_vm.isBoolean(header) ? [_c('div', {
            key: index
          }, [_vm.getNestedPropertyValue(item, _vm.keyValue) === _vm.getNestedPropertyValue(_vm.editedItem, _vm.keyValue) ? _c(VSimpleCheckbox, {
            model: {
              value: _vm.editedItem[header.value],
              callback: function ($$v) {
                _vm.$set(_vm.editedItem, header.value, $$v);
              },
              expression: "editedItem[header.value]"
            }
          }) : _c(VSimpleCheckbox, {
            attrs: {
              "disabled": ""
            },
            model: {
              value: item[header.value],
              callback: function ($$v) {
                _vm.$set(item, header.value, $$v);
              },
              expression: "item[header.value]"
            }
          })], 1)] : _vm.isColor(header) ? [_vm.getNestedPropertyValue(item, _vm.keyValue) === _vm.getNestedPropertyValue(_vm.editedItem, _vm.keyValue) && _vm.isColor(header) ? [_c(VTextField, {
            key: index,
            staticClass: "ma-0 pa-0",
            attrs: {
              "dense": "",
              "single-line": "",
              "hide-details": ""
            },
            scopedSlots: _vm._u([{
              key: "append",
              fn: function () {
                return [_c(VMenu, {
                  attrs: {
                    "top": "",
                    "nudge-bottom": "105",
                    "nudge-left": "16",
                    "close-on-content-click": false
                  },
                  scopedSlots: _vm._u([{
                    key: "activator",
                    fn: function ({
                      on
                    }) {
                      return [_c('div', _vm._g({
                        style: _vm.swatchStyle
                      }, on))];
                    }
                  }], null, true),
                  model: {
                    value: _vm.menu,
                    callback: function ($$v) {
                      _vm.menu = $$v;
                    },
                    expression: "menu"
                  }
                }, [_c(VCard, [_c(VCardText, {
                  staticClass: "pa-0"
                }, [_c(VColorPicker, {
                  attrs: {
                    "flat": ""
                  },
                  model: {
                    value: _vm.editedItem[header.value],
                    callback: function ($$v) {
                      _vm.$set(_vm.editedItem, header.value, $$v);
                    },
                    expression: "editedItem[header.value]"
                  }
                })], 1)], 1)], 1)];
              },
              proxy: true
            }], null, true),
            model: {
              value: _vm.editedItem[header.value],
              callback: function ($$v) {
                _vm.$set(_vm.editedItem, header.value, $$v);
              },
              expression: "editedItem[header.value]"
            }
          })] : _c(VCard, {
            key: index,
            staticClass: "rounded-pill fill-height elevation-0",
            style: {
              'background-color': _vm.getNestedPropertyValue(item, header.value)
            },
            attrs: {
              "align": "center",
              "justify": "center",
              "width": "35",
              "height": "35"
            }
          })] : _vm._e(), _vm.isArray(header) ? [_c('div', {
            key: index
          }, [_vm.getNestedPropertyValue(item, _vm.keyValue) === _vm.getNestedPropertyValue(_vm.editedItem, _vm.keyValue) ? _c(VAutocomplete, {
            attrs: {
              "value": _vm.getNestedPropertyValue(_vm.editedItem, header.value),
              "items": header.availableValues,
              "item-text": header.arrayValue,
              "return-object": "",
              "multiple": ""
            },
            on: {
              "change": newItems => _vm.setNestedProperty(_vm.editedItem, header.value, newItems)
            }
          }) : [_vm._v(_vm._s(_vm.getNestedPropertyValue(item, header.value).map(item => _vm.getNestedPropertyValue(item, header.arrayValue)).join(', ')))]], 2)] : [_c('div', {
            key: index
          }, [_vm.getNestedPropertyValue(item, _vm.keyValue) === _vm.getNestedPropertyValue(_vm.editedItem, _vm.keyValue) && !_vm.isANumber(header) ? _c(VTextField, {
            attrs: {
              "dense": "",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.editedItem[header.value],
              callback: function ($$v) {
                _vm.$set(_vm.editedItem, header.value, $$v);
              },
              expression: "editedItem[header.value]"
            }
          }) : _vm.getNestedPropertyValue(item, _vm.keyValue) === _vm.getNestedPropertyValue(_vm.editedItem, _vm.keyValue) && _vm.isANumber(header) && item[header.value] !== undefined ? _c(VTextField, {
            attrs: {
              "dense": "",
              "single-line": "",
              "hide-details": "",
              "type": "number",
              "disabled": _vm.actionMode != 'add'
            },
            model: {
              value: _vm.editedItem[header.value],
              callback: function ($$v) {
                _vm.$set(_vm.editedItem, header.value, $$v);
              },
              expression: "editedItem[header.value]"
            }
          }) : [_vm._v(_vm._s(_vm.getNestedPropertyValue(item, header.value)))]], 2)]];
        }
      };
    }), {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_vm.getNestedPropertyValue(item, _vm.keyValue) !== _vm.getNestedPropertyValue(_vm.editedItem, _vm.keyValue) ? [_c('LBAButton', {
          staticClass: "mr-2 col-xl-5",
          attrs: {
            "buttonStyle": "icon",
            "buttonSize": "small"
          },
          on: {
            "click": function ($event) {
              return _vm.editExistingItem(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-pencil ")])];
            },
            proxy: true
          }], null, true)
        }), _c('LBAButton', {
          staticClass: "col-xl-5",
          attrs: {
            "buttonType": "error",
            "buttonStyle": "icon",
            "buttonSize": "small"
          },
          on: {
            "click": function ($event) {
              return _vm.checkDeleteItem(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-delete ")])];
            },
            proxy: true
          }], null, true)
        })] : [_c('LBAButton', {
          staticClass: "mr-2 col-xl-5",
          attrs: {
            "buttonType": "error",
            "buttonStyle": "icon",
            "buttonSize": "small"
          },
          on: {
            "click": _vm.unAssignEditedItem
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-close ")])];
            },
            proxy: true
          }], null, true)
        }), !_vm.isNew ? _c('LBAButton', {
          staticClass: "col-xl-5",
          attrs: {
            "buttonStyle": "icon",
            "buttonSize": "small"
          },
          on: {
            "click": function ($event) {
              return _vm.checkSaveItem(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-content-save-all ")])];
            },
            proxy: true
          }], null, true)
        }) : _vm._e(), _vm.isNew ? _c('LBAButton', {
          staticClass: "col-xl-5",
          attrs: {
            "buttonStyle": "icon",
            "buttonSize": "small"
          },
          on: {
            "click": function ($event) {
              return _vm.saveNewItem(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-plus ")])];
            },
            proxy: true
          }], null, true)
        }) : _vm._e()]];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Daten vorhanden ")];
      },
      proxy: true
    }], null, true)
  }), _c('WarningDialog', {
    ref: "warningSave",
    on: {
      "warningActionPerformed": _vm.warningActionPerformedSave
    }
  }), _c('WarningDialog', {
    ref: "warningDelete",
    on: {
      "warningActionPerformed": _vm.warningActionPerformedDelete
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };